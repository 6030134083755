import React from "react";
import styled from "@emotion/styled"
import mixpanel from "mixpanel-browser"
import {graphql, Link, useStaticQuery} from "gatsby"

import Section from "@narative/gatsby-theme-novela/src/components/Section";
import Headings from "@narative/gatsby-theme-novela/src/components/Headings";
import SEO from "@narative/gatsby-theme-novela/src/components/SEO";
import Layout from "@narative/gatsby-theme-novela/src/components/Layout";
import { Template } from "@narative/gatsby-theme-novela/src/types";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";
import ArticlesList from "@narative/gatsby-theme-novela/src/sections/articles/Articles.List";


import Body from '../components/Body'
import { css } from "@emotion/react";

mixpanel.init(process.env.GATSBY_MIXPANEL_ID)

const MainDiv = styled(Body)`
  ${mediaqueries.desktop_medium_up`
    font-size: 1.25em;
  `}

  ${mediaqueries.tablet`
    font-size: 1em;
  `}

  ${mediaqueries.phablet`
    font-size: 1em;
  `}
`

const MainPage: Template = ({ location, pageContext }) => {
  mixpanel.track("Visit", {"Site": "danganiev.me", "Article": "Main"});
  const allArticles = useStaticQuery(graphql`
  query LastFiveArticles {
    allArticle(limit: 5, sort: {order: DESC, fields: date}) {
      edges {
        node {
          id
          author
          date(formatString: "MMMM Do, YYYY")
          slug
          title
          timeToRead
          excerpt
          hero {
            full: childImageSharp {
              fluid(maxWidth: 944, quality: 100) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            regular: childImageSharp {
              fluid(maxWidth: 653, quality: 100) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            narrow: childImageSharp {
              fluid(maxWidth: 457, quality: 100) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
            seo: childImageSharp {
              fixed(width: 1200, quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  }`)

  const articles = allArticles.allArticle.edges
    .filter((edge) => !edge.node.secret)
    .map((edge) => {
      return {
        ...edge.node,
        description: edge.node.excerpt,
        date: edge.node.date,
        url: edge.node.slug,
        guid: edge.node.slug,
        author: edge.node.author,
        title: edge.node.title,
        timeToRead: edge.node.timeToRead,
        hero: {
          regular: edge.node.hero.regular.fluid,
          full: edge.node.hero.full.fluid,
          narrow: edge.node.hero.narrow.fluid,
        },
      };
    });

  return (
    <Layout>
      <SEO pathname={location.pathname}></SEO>
      <Section>
        <MainDiv>
          <div>
            Hello, this is a personal page of Dan Ganiev. I am a software
            developer and entrepreneur, and I build and maintain small SaaS'es
            and video games in my spare time.
          </div>
          <br />

          <div>
            I am professionally interested in frontend, devops and gamedev, and
            unprofessionally curious about everything valuable to the mind, from
            cooking to anthropology.
          </div>
          <br />

          <div>
            You can learn about whatever I'm currently working on in{" "}
            <Link to="/now">now</Link> section. Or you can check out the
            collection of <Link to="/projects">projects</Link> I made over the
            years. I am not currently looking for a job, but here is my{" "}
            <Link to="/cv">CV</Link> if you're interested.
          </div>

          <br />
          <div>
            I also have social profiles. Here is my
            {" "}<a href="https://twitter.com/GanievDan" target="_blank">Twitter</a>,
            my {" "}<a href="https://github.com/danganiev" target="_blank">Github</a> and
            {" "}<a href="https://instagram.com/some_kind_of_dan" target="_blank">
              my account on some kind of photo sharing service
            </a>,{" "}which
              unfortunately tries to become a video sharing service.
          </div>
        </MainDiv>
      </Section>
      <Section css={css`margin-top:5rem`}>
      <Headings.h3>Latest blog posts:</Headings.h3>
      <br />
      <ArticlesList articles={articles}/>
      </Section>
    </Layout>
  );
};

export default MainPage;


